
import { map2list } from '@/store/constants';
import { copySelectTextContents, getUuid, setTimeoutSync } from '@mobro/libs';
import { Iobject } from '@mobro/libs/lib/type';
import { IRequestMethod, request } from '@mobro/request';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IMiniNameOptionKey, IMiniTypeOptionKey, miniNameOptionMap, miniTypeOptionsMap } from './constants';

@Component({})
export default class CreateWxacodeMultiDialog extends Vue {
    @Prop({ default: 50 }) maxSize!: number;
    searchForm = {
        mpt: 'release',
        mpn: 'ynnc',
        publishRegion: '',
        createWxacodeCount: 0,
        missionId: '',
        missionTime: '',
    };

    creating = false;
    createPercentage = 0;
    wxacodeImageList: Iobject[] = [];
    cancelMission = false;
    currentCreatedWxacodeCount = 0; // 当前已经完成创建的挪车码数量

    createWxacodeMissionQueue(count: number) {
        const { createWxacodeCount, ...params } = this.searchForm;

        this.createPercentage = Math.ceil((count * 10000) / createWxacodeCount) / 100;

        if (this.cancelMission) return;
        if (createWxacodeCount - count <= 0) {
            this.$alert(`任务完成! 共生成${createWxacodeCount}张二维码`);
            this.creating = false;
            return;
        }

        request({
            url: '/api/common/wxacode/get',
            method: IRequestMethod.GET,
            params: {
                ...params,
                scene: `id=${getUuid('xxyyyyxx1yyx')}`,
                page: 'pages/CarCallOwner/index',
                width: 600,
                is_hyaline: 1,
                isSystemCreate: 1,
            },
        }).then(async res => {
            if (this.wxacodeImageList.length < 500) {
                this.wxacodeImageList = this.wxacodeImageList.concat({
                    ...res,
                    mptLabel: miniTypeOptionsMap[res.envVersion as IMiniTypeOptionKey],
                });
            }
            await setTimeoutSync(400);
            this.currentCreatedWxacodeCount = count + 1;
            this.createWxacodeMissionQueue(count + 1);
        });
    }
    onCreateWxacode() {
        if (this.searchForm.createWxacodeCount > this.maxSize) {
            this.$message.error(`一次最多可新建${this.maxSize}张挪车码`);
            return;
        }

        this.creating = true;
        this.cancelMission = false;
        this.createWxacodeMissionQueue(0);
    }

    onCreateMissionId() {
        this.searchForm.missionId = getUuid('fxxxyyy');
    }

    onMultiCreate() {
        if (this.searchForm.createWxacodeCount > this.maxSize) {
            this.$message.error(`一次最多可新建${this.maxSize}张挪车码`);
            return;
        }

        const { createWxacodeCount, ...params } = this.searchForm;
        request({
            url: '/api/common/wxacode/batchcreate',
            method: IRequestMethod.POST,
            params: {
                ...params,
                page: 'pages/CarCallOwner/index',
                width: 600,
                is_hyaline: 1,
                isSystemCreate: 1,
                wxacodeCount: createWxacodeCount,
            },
        }).then(res => {
            if (res) {
                this.onCreateMissionId();
            }
        });
    }

    onCancelWxacode() {
        this.cancelMission = true;
        this.creating = false;
    }

    async onProseCopyClick(text: string) {
        await copySelectTextContents(text);
        this.$message.success('复制成功！');
    }

    get miniTypeOptionsList() {
        return map2list(miniTypeOptionsMap);
    }
    get miniNameOptionList() {
        return map2list(miniNameOptionMap);
    }

    // ========================================
    // ========== modal base template =========
    @Prop() value!: boolean;
    @Prop({ default: '弹窗' }) title!: string;

    visible = false;

    @Watch('value', { immediate: true })
    handleWatchValue(val: boolean) {
        this.visible = val;
        if (val) {
            this.onCreateMissionId();
        }
    }

    @Watch('visible', { immediate: true })
    handleWatchVisible(val: boolean) {
        this.$emit('input', this.visible);
    }
}
