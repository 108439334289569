
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Login from '@/mixins/login';
import { Iobject } from '@mobro/libs/lib/type';
import html2canvas from 'html2canvas';
import { WXACODE_TEMPLATE_CONFIG } from './config';
import { IRequestMethod, request } from '@mobro/request';
import { padStrat } from '@/libs/utils';
import CreateWxacodeMultiDialog from './CreateWxacodeMultiDialog.vue';

const addUnit = (x: string | number) => x + 'px';

@Component({
    components: {
        CreateWxacodeMultiDialog,
    },
})
export default class App extends mixins(Login) {
    paperConfig: Iobject = {};
    templateImageConfig: Iobject = {};
    wxacodeImageConfig: Iobject = {};
    wxacodeArr: Iobject[][] = [];
    done = false;
    serialNumber = ''; // 编号

    searchForm = {
        template: '', // 模板
        rows: '', // 几行
        columns: '', // 几列
        wxacodeCount: '', // 几个
        publishRegion: '', // 投放城市
        isUnique: true, // 每张图都是唯一码
    };
    isPreviewedPrintTemplate = false;
    // 挪车码模板列表
    wxaTemplateOptionList: Iobject[] = Object.entries(WXACODE_TEMPLATE_CONFIG).map(([wtcKey, wtcValue]) => {
        return {
            config: wtcValue,
            label: wtcKey,
            value: wtcKey,
            key: wtcKey,
        };
    });
    wxacodeIdList: string[] = [];

    async calcCanvasArea(currentConfig: Iobject) {
        try {
            const { paperConfig, templateImageConfig, wxacodeImageConfig, wxacodeCount, rows, columns } = currentConfig;

            // 计算纸张尺寸
            const paperWidth =
                (templateImageConfig.width + templateImageConfig.gap) * columns +
                (paperConfig.padding[1] + paperConfig.padding[3]);
            const paperHeight =
                (templateImageConfig.height + templateImageConfig.gap) * rows +
                (paperConfig.padding[0] + paperConfig.padding[2]);

            this.paperConfig = { ...paperConfig, width: paperWidth, height: paperHeight };
            this.templateImageConfig = templateImageConfig;
            this.wxacodeImageConfig = wxacodeImageConfig;

            const imageTemplate = await this.getImageTemplate(templateImageConfig.filename);
            const rectWxacodeCount =
                wxacodeCount === 0 || wxacodeCount > columns * rows ? columns * rows : wxacodeCount;
            const wxacodeImageList = await this.getWxacodeImageList(rectWxacodeCount);

            const wxacodeIdList: string[] = [];
            let wxacodeIndex = 0;
            const wxacodeArr = new Array(rows)
                .fill(0)
                .map((v, rowIndex) => {
                    if (wxacodeIndex >= rectWxacodeCount) return null;
                    return new Array(columns)
                        .fill(0)
                        .map((h, columnIndex) => {
                            wxacodeIndex = columns * rowIndex + columnIndex;
                            if (wxacodeIndex >= rectWxacodeCount) return null;

                            wxacodeIdList.push(wxacodeImageList[wxacodeIndex]?.wxacodeId);
                            return {
                                template: {
                                    ...templateImageConfig,
                                    url: imageTemplate.path,
                                },
                                wxacodeImageSerial: `No.${padStrat(wxacodeImageList[wxacodeIndex]?.id, 8)}`,
                                imageId: wxacodeImageList[wxacodeIndex]?.wxacodeId,
                                wxacode: {
                                    url: this.searchForm.isUnique
                                        ? wxacodeImageList[wxacodeIndex]?.path
                                        : require('@/assets/gh_db228f744a6c_430.jpg'),
                                    style: {
                                        width: addUnit(wxacodeImageConfig.width),
                                        height: addUnit(wxacodeImageConfig.height),
                                        left: addUnit(wxacodeImageConfig.left),
                                        top: addUnit(wxacodeImageConfig.top),
                                    },
                                },
                            };
                        })
                        .filter(s => s);
                })
                .filter(s => s);

            this.wxacodeArr = wxacodeArr as Iobject[][];
            this.done = true;
            this.wxacodeIdList = wxacodeIdList;
            console.log(wxacodeArr, '========', wxacodeIdList);
        } catch (e) {
            this.$message.error('数据处理有问题，请检查');
            // throw new Error('数据处理有问题，请检查');
            return Promise.reject('xxxxxx');
        }
    }

    handleImageUrl2Base64(url: string) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            // 解决跨域图片问题，就是上面提及的
            image.crossOrigin = 'anonymous';
            image.src = url;
            image.onload = () => {
                this.$nextTick(() => {
                    try {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        const width = image.width;
                        const height = image.height;
                        canvas.width = width;
                        canvas.height = height;
                        // 绘制到canvas上
                        if (ctx) {
                            ctx.drawImage(image, 0, 0, width, height);
                            // 将 canvas 转换为 base64 图片
                            const base64Image = canvas.toDataURL('image/jpeg');
                            resolve(base64Image);
                        }
                    } catch (e) {
                        reject(new Error('canvas转换错误，请检查'));
                    }
                });
            };
        });
    }

    // 获取二维码模板
    getImageTemplate(templateName: string) {
        return request({
            url: '/api/common/wxacode/imageTemplate',
            method: IRequestMethod.GET,
            params: {
                filename: templateName,
            },
        });
    }
    // 获取二维码图片
    getWxacodeImageList(count: number): Promise<Iobject[]> {
        return new Promise((resolve, reject) => {
            request({
                url: '/api/common/wxacode/list',
                method: IRequestMethod.POST,
                params: {
                    pageSize: count,
                    isPrint: 0,
                    mpt: 'release',
                    publishRegion: this.searchForm.publishRegion,
                },
            }).then(async res => {
                if (res.data.length < count) {
                    this.$message.error('二维码不够了，请先确保二维码数量');
                    return;
                }

                resolve(res.data || []);
            });
        });
    }

    handleWxaTemplateChange(template: string) {
        const currentTemplate = WXACODE_TEMPLATE_CONFIG[this.searchForm.template];

        this.searchForm.rows = currentTemplate.count[0];
        this.searchForm.columns = currentTemplate.count[1];
        this.searchForm.wxacodeCount = currentTemplate.wxacodeCount;
    }

    getWxaPrintTemplateId() {
        return request({
            url: '/api/common/wxacode/primaryid',
            method: IRequestMethod.GET,
        }).then(res => {
            this.serialNumber = padStrat(res, 6);
            return res;
        });
    }
    async onPreviewPosterItem() {
        try {
            if (this.isPreviewedPrintTemplate) {
                this.$message.warning('不要重复生成打印模板');
                return;
            }
            await this.getWxaPrintTemplateId();
            const currentTemplate = WXACODE_TEMPLATE_CONFIG[this.searchForm.template];

            await (this.$refs.searchFormRef as Iobject).validate();
            await this.calcCanvasArea({
                ...currentTemplate,
                rows: Number(this.searchForm.rows),
                columns: Number(this.searchForm.columns),
                wxacodeCount: Number(this.searchForm.wxacodeCount),
            });

            this.isPreviewedPrintTemplate = true;
        } catch (e) {
            //
        }
    }

    async onCreatePosterItem() {
        if (!this.isPreviewedPrintTemplate) {
            this.$message.error('请先生成打印模板');
            return;
        }
        const poster = document.querySelector('#poster') as HTMLElement;
        if (poster) {
            html2canvas(poster, {
                backgroundColor: null,
                useCORS: true,
            }).then(canvas => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                canvas.toBlob((blob: any) => {
                    const href = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = href;
                    link.download = `挪车码${this.serialNumber}.png`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    if (!this.searchForm.isUnique) return;

                    // 1、已经生成过得二维码做打印标记
                    request({
                        url: '/api/common/wxacode/updateisprint',
                        method: IRequestMethod.POST,
                        params: {
                            wxacodeIds: this.wxacodeIdList.join(','),
                            isPrint: 1,
                        },
                    }).then(res => {
                        if (res) {
                            // 2、不可重复生成
                            this.isPreviewedPrintTemplate = false;
                        }
                    });
                }, 'image/png');
            });
        } else {
            this.$message.error('未找到poster容器');
        }
    }

    @Watch('searchForm', { immediate: true, deep: true })
    handleWatchSearchForm() {
        this.isPreviewedPrintTemplate = false;
    }

    get serialNumberStyleMemo() {
        return {
            width: addUnit(this.paperConfig.padding[3]),
        };
    }
    get posterStyleMemo() {
        return {
            width: addUnit(this.paperConfig.width),
            height: addUnit(this.paperConfig.height),
            padding: (this.paperConfig.padding || []).map((s: number) => addUnit(s)).join(' '),
        };
    }
    get templateStyleMemo() {
        return {
            width: addUnit(this.templateImageConfig.width),
            height: addUnit(this.templateImageConfig.height),
            margin: addUnit(this.templateImageConfig.gap),
        };
    }

    get wxacodeStyleMemo() {
        return {
            position: 'absolute',
            borderRadius: '30px',
            overflow: 'hidden',
        };
    }

    // ========================================
    // ========== modal base template =========
    @Prop() value!: boolean;
    @Prop({ default: '弹窗' }) title!: string;

    visible = false;

    @Watch('value', { immediate: true })
    async handleWatchValue(val: boolean) {
        this.visible = val;
    }

    @Watch('visible', { immediate: true })
    handleWatchVisible(val: boolean) {
        this.$emit('input', this.visible);
    }
}
