import { Iobject } from '@mobro/libs/es/type';

export const WXACODE_TEMPLATE_CONFIG: Iobject = {
    simple1: getConfig({
        // 生成多少张二维码，传0则自动计算，默认0
        wxacodeCount: 0,

        count: [20, 9],
        rate: 1,

        paperConfig: {
            padding: [0, 0, 0, 80], // 边距
        },

        templateImageConfig: {
            filename: 'simple1.png',
            gap: 0, // 元素模板之间的间距
            width: 595, // 元素模板宽度
            height: 243, // 元素模板高度
        },

        wxacodeImageConfig: {
            width: 184, // 二维码宽度
            height: 184, // 二维码高度
            top: 29, // 二维码top
            left: 27, // 二维码left
        },
    }),
    simple2: getConfig({
        // 生成多少张二维码，传0则自动计算，默认0
        wxacodeCount: 0,
        count: [20, 9],
        rate: 1,

        paperConfig: {
            padding: [0, 0, 0, 80], // 边距
        },

        templateImageConfig: {
            filename: 'simple2.png',
            gap: 0, // 元素模板之间的间距
            width: 595, // 元素模板宽度
            height: 243, // 元素模板高度
        },

        wxacodeImageConfig: {
            width: 159, // 二维码宽度
            height: 159, // 二维码高度
            top: 14, // 二维码top
            left: 46, // 二维码left
        },
    }),
};

function getConfig(
    options = {
        // 生成多少张二维码，传0则自动计算，默认0
        wxacodeCount: 12,
        // 生成 [m行，n列] 个二维码
        count: [4, 2],
        // 倍率，默认1倍
        rate: 1,

        paperConfig: {
            padding: [0, 0, 0, 80], // 边距
        },

        templateImageConfig: {
            filename: 'simple1.png',
            gap: 0, // 元素模板之间的间距
            width: 595, // 元素模板宽度
            height: 243, // 元素模板高度
        },

        wxacodeImageConfig: {
            width: 184, // 二维码宽度
            height: 184, // 二维码高度
            top: 29, // 二维码top
            left: 27, // 二维码left
        },
    },
) {
    const rate = options.rate || 1;

    // 模板
    const templateFilename = options.templateImageConfig.filename || 'simple1.png';
    const templateGap = (options.templateImageConfig.gap || 0) * rate;
    const templateWidth = (options.templateImageConfig.width || 0) * rate;
    const templateHeight = (options.templateImageConfig.height || 0) * rate;

    // 二维码
    const wxacodeWidth = (options.wxacodeImageConfig.width || 0) * rate;
    const wxacodeHeight = (options.wxacodeImageConfig.height || 0) * rate;
    const wxacodeTop = (options.wxacodeImageConfig.top || 0) * rate;
    const wxacodeLeft = (options.wxacodeImageConfig.left || 0) * rate;

    // 纸张
    const paperPadding = options.paperConfig.padding.map(p => p * rate);

    return {
        // 生成多少张二维码，传0则自动计算，默认0
        wxacodeCount: options.wxacodeCount,
        count: options.count,
        rate: options.rate,

        paperConfig: {
            padding: paperPadding, // 边距
        },

        templateImageConfig: {
            filename: templateFilename,
            gap: templateGap, // 元素模板之间的间距
            width: templateWidth, // 元素模板宽度
            height: templateHeight, // 元素模板高度
        },

        wxacodeImageConfig: {
            width: wxacodeWidth, // 二维码宽度
            height: wxacodeHeight, // 二维码高度
            top: wxacodeTop, // 二维码top
            left: wxacodeLeft, // 二维码left
        },
    };
}
